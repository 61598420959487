import React from 'react'

function QuickMenu(props) {

    const { active, id, func, setNotifications } = props;
    return (
        <div className={active ? 'quick-menu' : 'quick-menu hide'}>
            {func ? func(setNotifications) : "no content :("}
        </div>
    )
}

export default QuickMenu;